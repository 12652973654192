import React from "react"

const Video = props => {
  return (
    <div style={{}}>
      <iframe
        style={{
          margin: "24px auto 0",
        }}
        width="624"
        height="378"
        src="https://www.youtube.com/embed/hw5SKFv8YNw"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      {props.de && (
        <p>Sven gibt ein paar Tipps zum Unterricht via Videoanruf.</p>
      )}
      {!props.de && <p>Sven gives some tips for teaching via video call.</p>}
      <iframe
        style={{
          margin: "24px auto 0",
        }}
        width="624"
        height="378"
        src="https://www.youtube.com/embed/98-DNf_rSiI"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      {props.de && (
        <p>
          In diesem Video erklärt Jasmin ausführlich, wie der Unterricht via
          Videoanruf am besten funktioniert.
        </p>
      )}
      {!props.de && (
        <p>
          In this video, Jasmin explains in detail how teaching via video call
          works best.
        </p>
      )}
      <iframe
        style={{
          margin: "24px auto 0",
        }}
        width="624"
        height="378"
        src="https://www.youtube.com/embed/YabtZwtClsw"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      {props.de && (
        <p>
          Zehn Tipps von Jasmin, wie man kreativen Online-Unterricht gestaltet.
        </p>
      )}
      {!props.de && (
        <p>
          Ten tips by Jasmin: How to do creative online lessons.
        </p>
      )}
      <iframe
        style={{
          margin: "24px auto 0",
        }}
        width="624"
        height="378"
        src="https://www.youtube.com/embed/M0wqe4yY1cs"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      {props.de && (
        <p>
          Ein kleines Video von Christian um seine Schüler*innen für den
          Unterricht via Videoanruf zu motivieren.
        </p>
      )}
      {!props.de && (
        <p>
          A small video by Christian to motivate his students to take lessons
          via video call.
        </p>
      )}
    </div>
  )
}

export default Video
