import React from "react"
import Video from "../components/videos"
import { graphql } from "gatsby"

export default ({ data: { post } }) => {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{ __html: post.childMarkdownRemark.html }}
      />
    {post.document.name === "9 - Inspiration" && < Video de={post.document.path === "/de/lehrpersonen/9-inspiration"} />}
    </>
  )
}

export const postQuery = graphql`
  query foo($path: String) {
    post: googleDocs(document: { path: { eq: $path } }) {
      document {
        name
        createdTime
        markdown
        path
      }
      childMarkdownRemark {
        html
      }
    }
  }
`
